<template>
    <div>
        <div id="chart">
            <apexchart
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            series: [
                {
                    name: "PRODUCT A",
                    data: [44, 55, 41, 67, 22, 43],
                },
                {
                    name: "PRODUCT B",
                    data: [13, 23, 20, 8, 13, 27],
                },
                {
                    name: "PRODUCT C",
                    data: [11, 17, 15, 15, 21, 14],
                },
                {
                    name: "PRODUCT D",
                    data: [21, 7, 25, 13, 22, 8],
                },
                {
                    name: "PRODUCT 3",
                    data: [10, 7, 25, 20, 20, 50],
                },
            ],
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        // borderRadius: 10,
                        columnWidth: "40%",
                    },
                },

                xaxis: {
                    type: "datetime",
                    categories: [
                        "01/01/2011 GMT",
                        "01/02/2011 GMT",
                        "01/03/2011 GMT",
                        "01/04/2011 GMT",
                        "01/05/2011 GMT",
                        "01/06/2011 GMT",
                    ],
                },
                legend: {
                    position: "left",
                    offsetY: 40,
                },
                fill: {
                    opacity: 1,
                },
            },
        };
    },
};
</script>
