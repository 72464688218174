<template>
  <div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t('message.personal')}}</div>
        <div>
          <span class="mr-3 cursorr" @click="printQr(staff.id)" v-can="'ProfileController@printQr'">
            <svg :class="mode ? 'svg__day' : 'svg__night'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z" fill="currentColor"/>
              <path opacity="0.3" d="M13 6.5C13 4 15 2 17.5 2C20 2 22 4 22 6.5C22 9 20 11 17.5 11C15 11 13 9 13 6.5ZM6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22ZM17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22Z" fill="currentColor"/>
            </svg>
          </span>
          <span class="mr-3 cursorr" @click="print(staff.id)" v-can="'ProfileController@print'">
            <svg :class="mode ? 'svg__day' : 'svg__night'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"/>
              <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"/>
              <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"/>
            </svg>
          </span>
          <span class="cursorr" @click="editStaff()" v-can="'StaffController@update'">
            <svg :class="mode ? 'svg__day' : 'svg__night'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <!-- <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div> -->
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{$t("message.id_employee")}}:</strong>
          <span>{{ staff.id }}</span>
        </p>

        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{$t("message.last_name")}}:</strong>
          <span>{{ staff.last_name }} </span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{$t("message.nameFull")}}:</strong>
          <span>{{ staff.name }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{$t("message.email")}}:</strong>
          <span>{{ staff.email }}</span>
        </p>
        <!-- <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">Личная эл. почта:</strong>
          <span>numonov.akromjon1998@gmail.com</span>
        </p> -->
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{$t("message.date_of_birth")}}:</strong>
          <span>{{ staff.date_of_birth }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{$t("message.passport_number")}} </strong>
          <span>{{ staff.passport_number }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{$t("message.passport_issued_by")}} </strong>
          <span>{{ staff.passport_issued_by }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{$t("message.passport_valid_until")}} </strong>
          <span>{{ staff.passport_valid_until }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{$t("message.inn")}} </strong>
          <span>{{ staff.inn }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{ $t("message.region") }} </strong>
          <span>{{ staff.region ? staff.region.name : "" }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{ $t("message.district") }} </strong>
          <span>{{ staff.district ? staff.district.name : "" }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold"> {{ $t("message.address") }} </strong>
          <span>{{ staff.address}}</span>
        </p>
      </div>
    </div>
    <!-- end card-table -->
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'" v-can="'ProfileController@getStaffContact'">
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.contact") }}</div>
        <i class="el-icon-edit" @click="contact = true" :class="mode ? '' : 'text__night2'" v-can="'ProfileController@addStaffContact'"></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadContact"
        v-if="staffContact"
        >
        <!-- <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div> -->
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.mobil_number") }}:</strong>
          <span>{{ staffContact.personal_phone_number }} </span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.work_phone") }}:</strong>
          <span>{{ staffContact.home_phone_number }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.facebook_url") }}:</strong>
          <span>{{ staffContact.facebook }} </span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.twitter_url") }}:</strong>
          <span>{{ staffContact.twitter }} </span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.instagram_url") }}:</strong>
          <span>{{ staffContact.instagram }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.linkedIn_url") }}:</strong>
          <span>{{ staffContact.linkedin }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.telegram_username") }}:</strong>
          <span>{{ staffContact.telegram }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.tiktok_username") }}:</strong>
          <span>{{ staffContact.tiktok }}</span>
        </p>
        <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
          <strong class="mr-1 font-bold">{{ $t("message.email") }}:</strong>
          <span>{{ staffContact.email }}</span>
        </p>
      </div>
    </div>
    <!-- end card-table -->

    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'" v-can="'ProfileController@getStaffEducation'">
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.education") }}</div>
        <i class="el-icon-plus" @click="educationCreate = true" :class="mode ? '' : 'text__night2'" v-can="'ProfileController@addStaffEducation'"></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadEducation"
      >
        <table class="w-100 td-30">
          <tr>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.educational_institution") }}</strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.degree") }} </strong>
            </td>
            <td colspan="2">
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.yearly") }}</strong>
            </td>
          </tr>
          <tr
            v-for="(education, index) in staffEducations"
            :key="'education' + index"
          >
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                <span> {{ education.name }} </span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                <span>{{ education.level }}</span>
              </p>
            </td>

            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                <span>{{
                  education.started_date + "-" + education.finished_date
                }}</span>
              </p>
            </td>

            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffEducation(education)"
                  v-can="'ProfileController@updateStaffEducation'"
                ></el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteEducation(education)"
                  v-can="'ProfileController@deleteStaffEducation'"
                  size="small"
                ></el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- end card-table -->

    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'" v-can="'ProfileController@getStaffSkill'">
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.add_skill") }}</div>
        <i class="el-icon-plus" @click="skillCreate = true" :class="mode ? '' : 'text__night2'" v-can="'ProfileController@addStaffSkill'"></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadSkill"
      >
        <table class="w-100 td-30">
          <tr>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.skill") }}</strong>
            </td>
            <td colspan="2">
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{ $t("message.Level") }}</strong>
            </td>
          </tr>
          <tr v-for="(skill, index) in staffSkills" :key="'skill' + index">
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                <span> {{ skill.skill }} </span>
              </p>
            </td>
            <td>
              <p class="text-left font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                <span>{{ skill.level }}</span>
              </p>
            </td>

            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffSkill(skill)"
                  v-can="'ProfileController@updateStaffSkill'"
                ></el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteSkill(skill)"
                  v-can="'ProfileController@deleteStaffSkill'"
                ></el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- end card-table -->
    <!-- start modal -->
    <!-- Contact modal -->
    <el-drawer
      :with-header="false"
      :visible.sync="drawerUpdate"
      size="95%"
      ref="drawerUpdate"
      @opened="drawerOpened('drawerUpdateChild')"
      @closed="drawerClosed('drawerUpdateChild')"
    >
      <crm-update-staff
        :selectedItem="staff"
        ref="drawerUpdateChild"
        drawer="drawerUpdate"
      ></crm-update-staff>
    </el-drawer>

    <!-- Contact modal -->
    <el-dialog
      class="ichki_modal"
      title="Добавить Contact"
      :visible.sync="contact"
      width="40%"
      ref="contact"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <contact :staff="staff"></contact>
    </el-dialog>

    <!-- Social modal -->

    <!-- Образование modal -->
    <el-dialog
      class="ichki_modal"
      title="Добавить Образование"
      :visible.sync="educationCreate"
      width="60%"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <create-education></create-education>
    </el-dialog>
    <el-dialog
      class="ichki_modal"
      title="Добавить Образование"
      :visible.sync="educationUpdate"
      width="60%"
      center
      @open="open('educationUpdate')"
    >
      <update-education
        ref="educationUpdate"
        :education="education"
      ></update-education>
    </el-dialog>

    <!-- Добавить навык modal -->
    <el-dialog
      class="ichki_modal"
      title="Добавить навык"
      :visible.sync="skillCreate"
      width="60%"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <create-skill ref="skillCreate"></create-skill>
    </el-dialog>
    <el-dialog
      class="ichki_modal"
      title="Добавить навык"
      :visible.sync="skillUpdate"
      width="60%"
      center
      @open="open('skillUpdate')"
    >
      <update-skill ref="skillUpdate" :skill="skill"></update-skill>
    </el-dialog>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import createSkill from "./components/create-skill.vue";
import updateSkill from "./components/update-skill.vue";
import createEducation from "./components/create-education.vue";
import updateEducation from "./components/update-education.vue";
import Contact from "./components/contact.vue";
import InfoUser from "./components/info-user.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import crmUpdateStaff from "@/views/staff/components/crm-update";
import $ from "jquery";
export default {
  components: {
    createEducation,
    crmUpdateStaff,
    Contact,
    InfoUser,
    createSkill,
    updateSkill,
    updateEducation,
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      skill: false,
      educationCreate: false,
      educationUpdate: false,
      licenses: false,
      social: false,
      contact: false,
      infoUser: false,
      drawerUpdate: false,
      loadContact: false,
      loadSkill: false,
      loadEducation: false,
      skillCreate: false,
      skillUpdate: false,
      education: {},
      skill: {},
    };
  },
  watch: {
    staff: {
      handler: function () {},
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.fetchStaffContact();
    this.fetchStaffEducation();
    this.fetchStaffSkill();
  },
  computed: {
    ...mapGetters({
      staffContact: "profile/staff_contact",
      staffEducations: "profile/staff_educations",
      staffSkills: "profile/staff_skills",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      show: "profile/show",
      getStaffContact: "profile/getStaffContact",
      getStaffEducation: "profile/getStaffEducation",
      deleteStaffEducation: "profile/deleteStaffEducation",
      getStaffSkill: "profile/getStaffSkill",
      deleteStaffSkill: "profile/deleteStaffSkill",
    }),
    fetchStaff() {
      if (this.$route.params.id) {
        this.show(this.$route.params.id)
          .then((res) => {})
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    editStaff() {
      this.drawerUpdate = true;
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    parent() {
      return this.$parent.$parent;
    },
    print(id) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL + `/staffs/profiles/print/${id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert("Не может печататься");
          vm.waitingPrint = false;
        });
    },
    printQr(id) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL + `/staffs/profiles/print-qr/${id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert("Не может печататься");
          vm.waitingPrint = false;
        });
    },
    closeDialog() {
      this.contact = false;
    },
    closeEducationDialog() {
      this.educationCreate = false;
      this.educationUpdate = false;
    },
    closeSkillDialog() {
      this.skillCreate = false;
      this.skillUpdate = false;
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.fetchStaff();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened();
        }
      }
    },
    listChanged() {
      this.reloadList = true;
    },
    fetchStaffContact() {
      if (!this.loadContact && this.$route.params.id) {
        this.loadContact = true;
        this.getStaffContact(this.$route.params.id)
          .then((res) => {
            this.loadContact = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadContact = false;
          });
      }
    },
    fetchStaffEducation() {
      if (!this.loadEducation && this.$route.params.id) {
        this.loadEducation = true;
        this.getStaffEducation(this.$route.params.id)
          .then((res) => {
            this.loadEducation = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadEducation = false;
          });
      }
    },
    fetchStaffSkill() {
      if (!this.loadSkill && this.$route.params.id) {
        this.loadSkill = true;
        this.getStaffSkill(this.$route.params.id)
          .then((res) => {
            this.loadSkill = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadSkill = false;
          });
      }
    },
    deleteEducation(education) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          if (!this.loadEducation && this.$route.params.id) {
            this.loadEducation = true;
            let form = {
              staff_id: this.$route.params.id,
              education_id: education.id,
            };
            this.deleteStaffEducation(form)
              .then((res) => {
                this.loadEducation = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.fetchStaffEducation();
                }
              })
              .catch((err) => {
                this.$alert(err);
                this.loadEducation = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    deleteSkill(skill) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          if (!this.loadSkill && this.$route.params.id) {
            this.loadSkill = true;
            let form = {
              staff_id: this.$route.params.id,
              skill_id: skill.id,
            };
            this.deleteStaffSkill(form)
              .then((res) => {
                this.loadSkill = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.fetchStaffSkill();
                }
              })
              .catch((err) => {
                this.$alert(err);
                this.loadSkill = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    editStaffEducation(education) {
      this.education = education;
      this.educationUpdate = true;
    },
    editStaffSkill(skill) {
      this.skill = skill;
      this.skillUpdate = true;
    },
    opened(refs) {
      this.$refs[refs].opened();
    },
    open(refs) {
      this.$refs[refs].opened();
    },
  },
};
</script>
