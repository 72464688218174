<template>
    <div>
      <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
        <div class="workers-info-title-za mobil-workers-info-title-za">
        <div :class="mode ? 'text__day2' : 'text__night2'" class="title">{{$t('message.report_card')}}</div>
       <div>
        <el-button :size="'small'"  @click="$refs.mounth_report.refreshReports()"  class="mi-icon mr-2" type="success" icon="el-icon-refresh">{{ $t("message.refresh") }} </el-button>

        <el-date-picker
          :class="mode ? 'input__day' : 'input__night'"
            size="medium"
            v-model="select_date"
            type="month"
            :format="'MMMM yyyy'"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
        </el-date-picker>
       </div>
    </div>
    <!-- <mounth-report-calendar   :staff_id="staff.id" :work_hour="staff.work_mounth_plane.limit.work_time_this_day"></mounth-report-calendar> -->
    <mounth-report-calendar ref="mounth_report" :activeSalaries="staff.activeSalaries" :select_date="select_date"  :staff_id="staff.id" :work_hour="staff.work_mounth_plane.limit.work_time_this_day"></mounth-report-calendar>
    <!-- <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="6">
                <div class="ml-5 mt-5 crmhome__box">
                    <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                        <div class="text-center">
                            <span :class="mode ? '' : 'textmuted__night'">{{$t('message.wage')}}</span>
                        </div>
                        <div class="text-center mt-3 profile__title" >
                            <b :class="mode ? 'text__day2' : 'text__night2'">{{Number(staff.activeSalaries).toLocaleString()}} сум</b>
                        </div>
                    </el-card>

                    <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                        <div class="text-center">
                            <span :class="mode ? '' : 'textmuted__night'">{{$t("message.limit_work_time")}}</span>
                        </div>
                        <div class="text-center mt-3 profile__title">
                            <b :class="mode ? 'text__day2' : 'text__night2'">{{Math.floor(staff.work_mounth_plane.limit.current_limit)}} {{$t('message.hour')}} {{Math.round((staff.work_mounth_plane.limit.current_limit - Math.floor(staff.work_mounth_plane.limit.current_limit))*60)}} {{$t('message.minut')}}
                             / {{Math.floor(staff.work_mounth_plane.limit.mounth_limit)}} {{$t('message.hour')}} {{Math.round((staff.work_mounth_plane.limit.mounth_limit - Math.floor(staff.work_mounth_plane.limit.mounth_limit))*60)}} {{$t('message.minut')}}
                            </b>
                        </div>
                    </el-card>

                    <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                        <div class="text-center">
                            <span :class="mode ? '' : 'textmuted__night'">{{$t("message.actual_working_time")}}</span>
                        </div>
                        <div class="text-center mt-3 profile__title">
                            <b style="color: #67c23a" v-if="staff.work_mounth_plane.work_time>staff.work_mounth_plane.limit.current_limit"> {{Math.floor(staff.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff.work_mounth_plane.work_time - Math.floor(staff.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                            <b style="color: red" v-else-if="(staff.work_mounth_plane.work_time>0)"> {{Math.floor(staff.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff.work_mounth_plane.work_time - Math.floor(staff.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                            <b style="color: red" v-else> {{staff.work_mounth_plane.work_time}} {{$t('message.hour')}} </b>
                        </div>
                    </el-card>
                    <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                      <div class="text-center">
                          <span :class="mode ? '' : 'textmuted__night'">{{$t("message.accept_vacation_managment")}}</span>
                      </div>
                      <div class="text-center mt-3 profile__title">
                         <b v-if="staff.last_vacation_managment" :class="mode ? 'text__day2' : 'text__night2'">{{formatMonthDate(staff.last_vacation_managment.month)}}</b>
                         <b v-else :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.not_attached")}}</b>
                      </div>
                    </el-card>
                </div>
            </el-col>
            <el-col :xs="24" :sm="18" class="crmhome__scroll">
                <table class="my-clendar mb-0">
                    <thead>
                        <tr>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Monday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Tuesday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Wednesday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Thursday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Friday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" style="color:#2d2e2c7d !important;">{{$t("message.Saturday")}}</div></th>
                            <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" style="color:#2d2e2c7d !important;">{{$t("message.Sunday")}}</div></th>
                        </tr>
                    </thead>
                    
                </table>
                <mounth-report-calendar :select_date="select_date"  :staff_id="staff.id" :work_hour="staff.work_mounth_plane.limit.work_time_this_day"></mounth-report-calendar>
                
            </el-col>
        </el-row>
    </div> -->
  </div>

        <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div :class="mode ? 'text__day2' : 'text__night2'" class="title">{{$t("message.salary_rotation")}}</div>
                <div>
                    <el-button
                        class="mi-icon"
                        type="success"
                        size="small"
                        icon="el-icon-refresh"
                    >
                        {{$t("message.rotation")}}
                    </el-button>
                </div>
            </div>
           
            <homePaymentChart :staff_id="staff.id"></homePaymentChart>
               
        </div>
        <staff-bonuses :staff_id="staff.id" v-can="'ProfileController@getBonuses'"></staff-bonuses>

        <staff-penalties :staff_id="staff.id" v-can="'ProfileController@getPenalties'"></staff-penalties>

        <profile-refa :staff_id="staff.id"></profile-refa>

        <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t("message.quality_control")}}</div>
            </div>
            <el-row :gutter="20" class="margin__none">
                <el-col :xs="24" :sm="8">
                    <div class="refa__item">
                        <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-primary__night'">
                            <i
                                class="my-icon el-icon-pie-chart text-primary"
                            ></i>
                            <div class="homepayment__flex">
                              <div class="text-center">
                                <span class="text-primary"> {{$t("message.process")}} </span>
                              </div>
                              <div class="text-center mt-3">
                                  <b class="text-primary">{{$t("message.name_process")}}</b>
                              </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <div class="refa__item">
                        <el-card class="box-card bg-light-success" :class="mode ? '' : 'text-success__night'">
                            <i
                                class="
                                    my-icon
                                    el-icon-collection-tag
                                    text-success
                                "
                            ></i>
                            <div class="homepayment__flex">
                              <div class="text-center">
                                <span class="text-success"> {{$t("message.norms")}} </span>
                              </div>
                              <div class="text-center mt-3">
                                  <b class="text-success">110</b>
                              </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <div class="refa__item">
                        <el-card class="box-card bg-light-danger" :class="mode ? '' : 'text-danger__night'">
                            <i
                                class="my-icon el-icon-data-line text-danger"
                            ></i>
                            <div class="homepayment__flex">
                              <div class="text-center">
                                <span class="text-danger"> {{$t("message.fact")}} </span>
                              </div>
                              <div class="text-center mt-3">
                                  <b class="text-danger"> 104 </b>
                              </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>

            <homeControlChart class="control__chart"></homeControlChart>
        </div>
        <!-- end  -->

        <profile-take-assent :staff_id="staff.id"></profile-take-assent>
        <!-- end card Изн -->
        
        <advance-salary :staff_id="staff.id"></advance-salary>
        <!-- end card Аванс -->
  
        <profile-complaint :staff_id="staff.id"></profile-complaint>
        <!-- end card Жалобы -->

        <additional-work :staff_id="staff.id"></additional-work>

    </div>
</template>

<script>
import HomeRefaChart from "./components/home-refa-chart.vue";
import staffBonuses from "./components/staff-bonuses.vue";
import staffPenalties from "./components/staff-penalties.vue";
import HomePaymentChart from "./components/home-payment-chart.vue";
import HomeControlChart from "./components/home-control-chart.vue";
import AdvanceSalary from "./components/advanceSalary.vue";
import ProfileComplaint from "./components/profileComplaint.vue";
import AdditionalWork from "./components/additionalWork.vue";
import ProfileTakeAssent from "./components/profileTakeAssent.vue";
import MounthReportCalendar from "./components/mounth-report-calendar.vue";
import ProfileRefa from "./components/profile-refa.vue";
import moment from 'moment';
import {mapGetters} from 'vuex'
export default {
    components: {
        HomeRefaChart,
        HomePaymentChart,
        HomeControlChart,
        AdvanceSalary,
        ProfileComplaint,
        ProfileTakeAssent,
        MounthReportCalendar,
        ProfileRefa,
        staffBonuses,
        staffPenalties,
        AdditionalWork
    },
     props: {
      staff: {
        type: Object,
        required: true,
      },
    },
    data() {
        return {
          select_date : moment().format("YYYY-MM-DD"),
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() > Date.now() - 8.64e7;
            }
          } 
        };
    },
    computed: {
      ...mapGetters({
        mode: "MODE"
      })
    },
    methods: {
      formatMonthDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
        }
    }
    
};
</script>

<style lang="scss">
.small_button{
  font-size: 14px;
  color: #fff;
}
.crmhome__box{
  .box-card{
    .el-card__body{
      padding: 15px !important;

    }
  }
}
.cardtable__night .my-table-card thead tr th{
  background-color: #424662ad !important;

}
</style>
