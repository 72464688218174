<template>
  <div>
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Фамилия </span>
            <el-input
              placeholder="Фамилия"
              v-model="form.value2"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Отчество </span>
            <el-input
              placeholder="Отчество"
              v-model="form.value3"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Имя </span>
            <el-input
              placeholder="Имя"
              v-model="form.value4"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Дата рождения </span>
            <el-date-picker
              v-model="value5"
              type="date"
              placeholder="Дата рождения"
              size="medium"
              class="w-100"
            >
            </el-date-picker>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Паспорт серия </span>
            <el-input
              placeholder="Паспорт серия"
              v-model="form.value6"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Кем выдан паспорт </span>
            <el-input
              placeholder="Кем выдан паспорт"
              v-model="form.value7"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">
              Срок действия паспорта
            </span>
            <el-date-picker
              v-model="value8"
              type="date"
              placeholder="Срок действия паспорта"
              size="medium"
              class="w-100"
            >
            </el-date-picker>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Эл. почта </span>
            <el-input
              placeholder=" Эл. почта"
              v-model="form.value9"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Личная эл. почта </span>
            <el-input
              placeholder=" Личная эл. почта"
              v-model="form.value10"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> Уровень дохода </span>
            <el-select
              class="w-100"
              v-model="form.value11"
              placeholder="Уровень дохода"
              size="medium"
            >
              <el-option
                label="Низкий уровень дохода"
                value="shangh"
              ></el-option>
              <el-option
                label="средний уровень дохода"
                value="shangh"
              ></el-option>
              <el-option
                label="Высокий уровень дохода"
                value="beijing"
              ></el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">Пол</span>
            <el-select
              class="w-100"
              v-model="form.value11"
              placeholder="Пол"
              size="medium"
            >
              <el-option label="Zone one" value="shangh"></el-option>
              <el-option label="Zone two" value="beijing"></el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2"> ИНН </span>
            <el-input
              placeholder="ИНН"
              v-model="form.value6"
              size="medium"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
      <!-- end row -->
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button
        type="success"
        size="medium"
        @click="centerDialogVisible = false"
        plain
      >
        Сохранить
      </el-button>
      <el-button
        type="warning"
        size="medium"
        @click="centerDialogVisible = false"
        plain
      >
        Закрыть
      </el-button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {},
};
</script>
