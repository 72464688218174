<template>
  <div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t('message.status')}}</div>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <div v-loading="loadStatus">
          <div class="ststu-radio" v-if="statuses">
            <el-radio-group v-model="form.status_id" size="small">
              <div v-for="(status, index) in statuses" :key="'status' + index">
                <el-radio :label="status.id" border>
                 
                  {{ status.name }} - {{ getStatusDates(status) }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
          <div
            class="app-form__group mb-4"
            style="width: 250px"
            v-if="showReason"
          >
            
            <span class="input--label d-block mb-2">{{$t('message.reason')}}</span>
            <select-reason
              :id="form.reason_id"
              v-model="form.reason_id"
            ></select-reason>

            <span class="input--label d-block my-2 ">{{$t("message.datas")}}</span>
            <el-date-picker
                v-model="form.fired_date"
                size="small"
                type="date"
                width="100%"
                style="width:100%"
                value-format="yyyy-MM-dd"
                placeholder="Причина дате">
              </el-date-picker>
              <div v-if="staff.children.length">
                <span class="input--label d-block mt-2 mb-2">{{$t('message.manager')}}</span>
                <select-staff
                  :id="form.manager_id"
                  v-model="form.manager_id"
                ></select-staff>
              </div>
             
          </div>
        </div>
        <el-button type="primary" size="medium" @click="updateStatusAndReason()"
          >{{$t('message.save')}}</el-button
        >
      </div>
    </div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{$t('message.staff_work_types')}}</div>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <div v-loading="loadEmloyment">
          <div class="ststu-radio">
            <el-radio-group v-model="form.work_type_id" size="small">
              <div v-for="(work, index) in workTypes" :key="'status' + index">
                <el-radio :label="work.id" border>
                  {{ work.work_type_name }} - {{ getWorkTypes(work) }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>

        <el-button type="primary" @click="updateEmploymentType()" size="medium"
          >{{$t('message.save')}}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import selectStatus from "@/components/filters/inventory/select-status";
import selectReason from "@/components/filters/inventory/select-reason";
import selectEmploymentType from "@/components/filters/inventory/select-employmentType";
import { mapGetters, mapActions, mapState } from "vuex";
import selectStaff from '@/components/filters/inventory/select-staff.vue';
export default {
  components: {
    selectStatus,
    selectReason,
    selectEmploymentType,
    selectStaff,
  },
  data() {
    return {
      form: {
        status_id: null,
        reason_id: null,
        work_type_id: null,
        fired_date:''
      },
      loadStatus: false,
      loadEmloyment: false,
      showReason: false,
    };
  },
  props: {
    staff: {
      required: true,
      type: Object,
    },
  },

  watch: {
    "form.status_id": {
      handler: function () {
        this.updateStatus();
      },
      deep: true,
      immediate: true,
    },
    "form.reason_id": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
    "form.fired_date": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
    "form.work_type_id": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.fetchStatuses();
    await this.fetchStaffStatusAndReason();
    await this.fetchEmploymentType();
    await this.fetchStaffEmloymentType();
    this.form.status_id = this.staffStatusAndReason.status
      ? this.staffStatusAndReason.status.id
      : "";
    this.form.reason_id = this.staffStatusAndReason.reason
      ? this.staffStatusAndReason.reason.id
      : "";
    
    this.form.work_type_id = this.work_type 
      ? this.work_type.id 
      : "";

  },
  computed: {
    ...mapGetters({
      staffStatusAndReason: "profile/staff_status_and_reason",
      work_type: "profile/work_type",
      statuses: "status/inventory",
      workTypes: "workType/inventory",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getStaffStatusAndReason: "profile/getStaffStatusAndReason",
      getStatus: "status/inventory",
      updateStaffStatusAndReason: "profile/updateStaffStatusAndReason",
      workTypeInventory: "workType/inventory",
      getStaffWorkType: "profile/getStaffWorkType",
      updateStaffWorkType: "profile/updateStaffWorkType",
    }),
    async fetchStaffStatusAndReason() {
      if (this.$route.params.id) {
        await this.getStaffStatusAndReason(this.$route.params.id)
          .then((res) => {})
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    async fetchStatuses() {
      if (!this.loadStatus) {
        this.loadStatus = true;
        const query = { table_name: "staffs" };
        await this.getStatus(query)
          .then((res) => {
            this.loadStatus = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadStatus = false;
          });
      }
    },
    async fetchEmploymentType() {
      if (!this.loadEmloyment) {
        this.loadEmloyment = true;
        await this.workTypeInventory()
          .then((res) => {
            this.loadEmloyment = false;
          })
          .catch((err) => {
            this.loadEmloyment = false;
          });
      }
    },
    async fetchStaffEmloymentType() {
      if (this.$route.params.id) {
        await this.getStaffWorkType(this.$route.params.id)
          .then((res) => {})
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    updateStatusAndReason() {
      this.form.staff_id = this.$route.params.id;
      if (!this.loadStatus && this.form.staff_id) {
        this.loadStatus = true;
        this.updateStaffStatusAndReason(this.form)
          .then((res) => {
            this.$alert(res);
            this.$emit("statusUpdated", true);
            this.loadStatus = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadStatus = false;
          });
      }
    },
    updateEmploymentType() {
      this.form.staff_id = this.$route.params.id;
      this.updateStaffWorkType(this.form)
        .then((res) => {
          this.$alert(res);
          this.$emit("statusUpdated", true);
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    updateStatus() {
      if (this.form.status_id) {
        let status_id = this.form.status_id;
        if (this.statuses.length != 0) {
          let status = _.find(this.statuses, function (o) {
            return o.id == status_id;
          });
          if (status.code && status.code == -1) {
            this.showReason = true;
          } else {
            this.showReason = false;
          }
        }
      }
    },
    getStatusDates(status) {
      if (status.code == 0) {
        return this.staff.testing_date;
      }
      if (status.code == -1) {
        return this.staff.fired_date;
      }
      if (status.code == 1) {
        return this.staff.hiring_date;
      }
    },
    getWorkTypes(status) {
      if (status.work_type_name == "Официальный") {
        return this.staff.official_work_date;
      }
      if (status.work_type_name == "Неофициальный") {
        return this.staff.unofficial_work_date;
      }
    },
  },
};
</script>
