<template>
  <div>
    <!--  card-table -->
    <client-language v-can="'ProfileController@getClientLanguage'"></client-language>
    <!-- end card-table -->

    <!-- start Лицензии и сертификаты -->
    <clientCertificates v-can="'ProfileController@getClientCertificate'"></clientCertificates>
    <!-- end Лицензии и сертификаты -->
    
    <!-- start Информация о здоровье -->
    <clientHealthInformation v-can="'ProfileController@getClientHealthInformation'"></clientHealthInformation>
    <!-- end Информация о здоровье -->
    
  </div>
</template>
<script>
import ClientCertificates from "./components/client-certificates";
import ClientLanguage from "./components/client-language";
import ClientHealthInformation from "./components/client-health-information";
export default {
  components: {
    ClientCertificates,
    ClientHealthInformation,
    ClientLanguage,
  },
  data() {
    return {};
  },
};
</script>
